import React, { useState, useEffect, useRef, useCallback } from "react";

import * as Yup from "yup";

import { endUserLocationGet } from "services/mia-services/EndUser/EndUserLocationGet";
import { RequestServiceGet } from "services/mia-services/RequestServiceGet";
import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import {
    userCredentials,
    snackbarData,
    secondaryModalData,
} from "recoil/GlobalState";
import { Form, Formik } from "formik";

import CircularIndeterminate from "components/UI/Spinner/Spinner";
import { Button, SecondaryModal } from "components";
import GgleMap from "components/UI/Map/GoogleMap";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";

import MapaModal from "./MapaModal";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";

import styles from "styles/pages/usuariosBO/LocationModal.module.scss";

export default function LocationModal({ closeModal, updateModalList, userId }) {
    const [userInfo, setUserInfo] = useState({});
    const [provincias, setProvincias] = useState([]);
    const [localidades, setLocalidades] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [inRequest, setInRequest] = useState(false);
    const [estadoRegistro, setEstadoRegistro] = useState("");

    const [secondaryModalDetails, setSecondaryModalDetails] =
        useRecoilState(secondaryModalData);

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const formikRef = useRef();

    const getUserInfo = useCallback(async (usuarioId) => {
        const params = { usuarioId };
        const payload = { ...credentials, params };
        const data = await endUserLocationGet(payload);

        setUserInfo(data);
    }, [credentials]);

    const getProvincias = useCallback(async () => {
        const body = {
            service: "ProvinciaGet",
            params: {},
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        setProvincias(result);
    }, [credentials]);

    const getLocalidades = useCallback(async (provinciaId, localidadId) => {
        setInRequest(true);

        const body = {
            service: "LocalidadGet",
            params: { provinciaId: provinciaId ?? userInfo.provinciaId },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        setLocalidades(result);
        formikRef.current?.setFieldValue("localidadId", localidadId ?? result[0]?.localidadId);

        setInRequest(false);
    }, [credentials]);

    useEffect(() => {
        if (!credentials) {
            return;
        }

        const init = async () => {
            setIsLoading(true);

            await Promise.all([
                getUserInfo(userId),
                getProvincias()
            ]);

            setIsLoading(false);
        };

        init();
    }, [credentials]);

    useEffect(() => {
        if (credentials && userInfo.provinciaId) {
            getLocalidades(userInfo.provinciaId, userInfo.localidadId);
        }
    }, [credentials, userInfo.provinciaId, provincias]);

    const handleSubmit = async (values) => {
        setInRequest((inRequest) => !inRequest);

        const body = {
            service: "EndUserOnboardDomicilioBOUpd",
            params: {
                domicilioXUsuarioId: values.domicilioXUsuarioId,
                estadoDeRegistroCod: estadoRegistro,
                domicilioCalle: values.domicilioCalle,
                domicilioCasa: values.domicilioCasa,
                domicilioPiso: values.domicilioPiso,
                domicilioUnidad: values.domicilioUnidad,
                domicilioCPA: values.domicilioCPA,
                domicilioOtros: values.domicilioOtros,
                domicilioLongitud:
                    values.domicilioLongitud ||
                    parseFloat(userInfo.domicilioLongitud).toFixed(6),
                domicilioLatitud:
                    values.domicilioLatitud ||
                    parseFloat(userInfo.domicilioLatitud).toFixed(6),
                localidadId: values.localidadId,
                domicilioXUsuarioVersion: values.domicilioXUsuarioVersion,
            },
        };

        await RequestServiceActions(body, credentials, null, setSnackbarInfo);

        closeModal();
        updateModalList();
    };

    const handleChangeProvincia = (event) => {
        const provinciaId = Number(event.target.value);
        formikRef.current.setFieldValue("provinciaId", provinciaId);
        getLocalidades(provinciaId);
    };

    const handleChangeLocalidad = (event) => {
        const localidadId = Number(event.target.value);
        formikRef.current.setFieldValue("localidadId", localidadId);
    };

    console.log(formikRef);

    return isLoading ? (
        <CircularIndeterminate />
    ) : (
        <div className={styles.container}>
            <SecondaryModal>
                <MapaModal
                    procede="location-mapa"
                    setSecondaryModalDetails={setSecondaryModalDetails}
                    setUserInfo={setUserInfo}
                    userInfo={userInfo}
                    lat={userInfo ? userInfo.domicilioLatitud : null}
                    lng={userInfo ? userInfo.domicilioLongitud : null}
                />
            </SecondaryModal>

            <h5 className={styles.title}>Datos de locación</h5>

            <Formik
                innerRef={formikRef}
                initialValues={{
                    domicilioScan: userInfo.domicilioScan || "N/A",
                    domicilioXUsuarioId: userInfo.domicilioXUsuarioId || "",
                    estadoDeRegistroCod: estadoRegistro || "",
                    domicilioCalle: userInfo.domicilioCalle || "",
                    domicilioCasa: userInfo.domicilioCasa || "",
                    domicilioPiso: userInfo.domicilioPiso || "",
                    domicilioUnidad: userInfo.domicilioUnidad || "",
                    domicilioCPA: userInfo.domicilioCPA || "",
                    domicilioOtros: userInfo.domicilioOtros || "",
                    domicilioLongitud: userInfo.domicilioLongitud || "",
                    domicilioLatitud: userInfo.domicilioLatitud || "",
                    provinciaId: userInfo.provinciaId || "",
                    localidadId: userInfo.localidadId || "",
                    domicilioXUsuarioVersion: userInfo.domicilioXUsuarioVersion,
                }}
                validationSchema={Yup.object({
                    domicilioCalle: Yup.string(
                        FORM_ERROR_MESSAGES.text
                    ).required(FORM_ERROR_MESSAGES.required),
                    domicilioCasa: Yup.string(
                        FORM_ERROR_MESSAGES.text
                    ).required(FORM_ERROR_MESSAGES.required),
                    domicilioPiso: Yup.number(FORM_ERROR_MESSAGES.number),
                    domicilioUnidad: Yup.string(FORM_ERROR_MESSAGES.text),
                    domicilioCPA: Yup.string(FORM_ERROR_MESSAGES.text).required(
                        FORM_ERROR_MESSAGES.required
                    ),
                    domicilioOtros: Yup.string(FORM_ERROR_MESSAGES.text),
                    localidadId: Yup.number(
                        FORM_ERROR_MESSAGES.number
                    ).required(FORM_ERROR_MESSAGES.required),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    try {
                        handleSubmit(values);
                    } catch (e) {
                        setSnackbarInfo({
                            message: e.errmsg,
                            severity: "error",
                            open: true,
                        });
                        setTimeout(() => {
                            setSnackbarInfo((data) => !data.open);
                        }, 3000);
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                <Form className={styles.form} noValidate>
                    <FormikTextInput
                        label="Domicilio Renaper"
                        name="domicilioScan"
                        placeholder=""
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    />

                    <div className={styles.row}>
                        <FormikTextInput
                            label="Calle"
                            name="domicilioCalle"
                            className={styles.inputBig}
                        />

                        <div className={`${styles.row} ${styles.inputBig}`}>
                            <FormikTextInput
                                label="Nro"
                                name="domicilioCasa"
                                className={styles.column}
                            />

                            <FormikTextInput
                                label="Piso"
                                name="domicilioPiso"
                                className={styles.column}
                            />
                        </div>

                        <FormikTextInput
                            label="Departamento"
                            name="domicilioUnidad"
                            className={styles.inputSm}
                        />

                        <FormikTextInput
                            label="Código Postal"
                            name="domicilioCPA"
                            className={styles.inputSm}
                        />
                    </div>

                    <div className={styles.row}>
                        <FormikSelectInput
                            label="Provincia"
                            name="provinciaId"
                            className={styles.inputBig}
                            disabled={!provincias.length}
                            onChange={handleChangeProvincia}
                        >
                            {provincias.map(({ provinciaId, provinciaNombre }) => (
                                <option
                                    key={provinciaId}
                                    value={provinciaId}
                                >
                                    {provinciaNombre}
                                </option>
                            ))}
                        </FormikSelectInput>

                        <FormikSelectInput
                            label="Localidad"
                            name="localidadId"
                            className={styles.inputBig}
                            disabled={!localidades.length}
                            onChange={handleChangeLocalidad}
                        >
                            {localidades.map(
                                ({ localidadId, localidadNombre }) => {
                                    return (
                                        <option
                                            key={localidadId}
                                            value={localidadId}
                                        >
                                            {localidadNombre}
                                        </option>
                                    );
                                }
                            )}
                        </FormikSelectInput>

                        <FormikTextInput
                            label="Latitud"
                            name="domicilioLatitud"
                            className={styles.inputSm}
                            errorParam={formikRef.current?.errors?.domicilioLatitud}
                            disabled
                            value={
                                userInfo.domicilioLatitud
                                    ? parseFloat(
                                        userInfo.domicilioLatitud
                                    ).toFixed(6)
                                    : ""
                            }
                            onChange={(e) => {
                                formikRef.current.setFieldValue('domicilioLatitud', e.target.value);
                                formikRef.current.setErrors({ domicilioLatitud: null });
                            }}
                        />

                        <FormikTextInput
                            label="Longitud"
                            name="domicilioLongitud"
                            className={styles.inputSm}
                            errorParam={formikRef.current?.errors?.domicilioLongitud}
                            disabled
                            value={
                                userInfo.domicilioLongitud
                                    ? parseFloat(
                                        userInfo.domicilioLongitud
                                    ).toFixed(6)
                                    : ""
                            }
                            onChange={(e) => {
                                formikRef.current.setFieldValue('domicilioLongitud', e.target.value);
                                formikRef.current.setErrors({ domicilioLongitud: null });
                            }}
                        />
                    </div>

                    <div className={styles.row}>
                        <div className={styles.mapa}>
                            {(userInfo.domicilioLatitud ||
                                userInfo.domicilioLongitud) && (
                                    <GgleMap
                                        lat={userInfo.domicilioLatitud}
                                        lng={userInfo.domicilioLongitud}
                                        customCenter={{
                                            lat: userInfo.domicilioLatitud,
                                            lng: userInfo.domicilioLongitud,
                                        }}
                                    />
                                )}

                            {(!userInfo.domicilioLongitud ||
                                !userInfo.domicilioLatitud) && (
                                    <p>
                                        No hay ubicación establecida para el
                                        usuario, por favor establezcala.
                                    </p>
                                )}
                        </div>

                        <Button
                            className={styles.buttonCoordenadas}
                            onClick={event => {
                                event.preventDefault();
                                setSecondaryModalDetails({
                                    ...secondaryModalDetails,
                                    isOpen: true,
                                });
                            }}
                            texto={"Buscar coordenadas"}
                            type="button"
                        />

                        <div className={styles.buttons}>
                            <FormikTextInput
                                label="Otros"
                                name="domicilioOtros"
                            />

                            <div className={styles.buttonRow}>
                                <Button
                                    disabled={inRequest}
                                    onClick={() => setEstadoRegistro("A")}
                                    texto={"Confirmar"}
                                />

                                <Button
                                    disabled={inRequest}
                                    onClick={() => setEstadoRegistro("R")}
                                    texto={"Rechazar"}
                                />

                                <Button
                                    disabled={inRequest}
                                    onClick={event => { event.preventDefault(); closeModal(); }}
                                    texto={"Cerrar"}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}
