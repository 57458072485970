import React, { useCallback, useEffect, useState } from "react";

import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";
import { Button } from "components";

import { Formik, Form } from "formik";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { snackbarData, userCredentials } from "recoil/GlobalState";

import styles from "styles/pages/usuariosNoUsuarios/UsuariosNoUsuariosFilters.module.scss";
import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

export default function UsuariosNoUsuariosFilters({ handleSubmit, setFilters, filters }) {
    const [aplicaciones, setAplicaciones] = useState([]);

    const credentials = useRecoilValue(userCredentials);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const getApplications = useCallback(async () => {
        const body = {
            service: "AplicacionGet",
            params: {
                listAll: 'Y'
            },
        };

        const response = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        setAplicaciones(response);
    }, [credentials, setSnackbarInfo]);

    useEffect(() => {
        if (Object.keys(credentials).length > 0) {
            getApplications();
        }
    }, [credentials]);

    return (
        <Formik
            initialValues={{
                usuarioNroDeDocumentoX: filters.usuarioNroDeDocumentoX || "",
                aplicacionId: filters.aplicacionId || ""
            }}
            onSubmit={(
                {
                    usuarioNroDeDocumentoX,
                    aplicacionId
                },
                { setSubmitting }
            ) => {
                try {
                    const params = {
                        usuarioNroDeDocumentoX: usuarioNroDeDocumentoX?.replace(
                            /\./g,
                            ""
                        ),
                        aplicacionId
                    };

                    setFilters(params);
                    handleSubmit(params);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.form} noValidate>
                <FormikTextInput
                    className={styles.input}
                    placeholder={"Documento"}
                    name="usuarioNroDeDocumentoX"
                />

                <FormikSelectInput
                    className={styles.input}
                    disabled={!aplicaciones.length}
                    fullWidth={true}
                    name="aplicacionId"
                >
                    <option value="">Aplicaciones</option>
                    {aplicaciones?.map(({ aplicacionId, aplicacionNombre }) => (
                        <option key={aplicacionId} value={aplicacionId}>
                            {aplicacionNombre}
                        </option>
                    ))}
                </FormikSelectInput>

                <Button texto={"Buscar"} />
            </Form>
        </Formik >
    );
}
