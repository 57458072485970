import axiosPost from "../axiosPost";

export const NotificatorUsuarioRejectedSend = async (arg) => {
    const name = "Notificator Usuario Status Send";
    let result;

    const { params } = arg;

    const body = {
        service: "NotificatorUsuarioRejectedSend",
        params: params ? params : {},
    };

    try {
        result = await axiosPost("Y", body, arg);
    } catch (err) {
        console.log(err);
    }

    if (!result || result === null) {
        console.log(`Error getting ${name}`);
    }

    if (result?.status?.code !== 1) {
        console.error('mail not sent');
    }

    return result;
};
