import React, { useEffect, useRef, useState } from "react";

import { TextField } from "@mui/material";

import { endUserIdentityGet } from "services/mia-services/EndUser/EndUserIdentityGet";
import { endUserIdentityUpd } from "services/mia-services/EndUser/EndUserIdentityUpd";
import { NotificatorUsuarioStatusSend } from "services/mia-services/Notificator/NotificatorUsuarioStatusSend";

import { useSetRecoilState, useRecoilValue } from "recoil";
import { userCredentials, snackbarData } from "recoil/GlobalState";

import styles from "styles/pages/usuariosBO/IdentityModal.module.scss";
import { Button, CircularIndeterminate } from "components";
import { RequestServiceGet } from "services/mia-services/RequestServiceGet";
import ConfirmationModal from "components/UI/Modals/ConfirmationModal";
import { NotificatorUsuarioRejectedSend } from "services/mia-services/Notificator/NotificatorUsuarioRejectedSend";
import Cropper from "react-cropper";
import {usuarioFotoExtraidaDNIUpd} from "../../../services/mia-services/EndUser/UsuarioFotoExtraidaDNIUpd";

export default function IdentityModal({ closeModal, closAllModal, updateModalList, userId }) {
    const [comentario, setComentario] = useState("");
    const [userInfo, setUserInfo] = useState();
    const [frenteDNI, setFrenteDNI] = useState(true);
    const [inRequest, setInRequest] = useState(false);
    const [inCrop, setInCrop] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState({
        onConfirm: () => { },
        title: '',
        text: '',
        isOpen: false
    });

    const fotoDNI = useRef(null);
    const cropperRef = useRef(null);


    const credentials = useRecoilValue(userCredentials);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    useEffect(() => {
        const params = { usuarioId: userId };

        const payload = { ...credentials, params };

        (async () => {
            const data = await endUserIdentityGet(payload);
            setUserInfo(data);
            setComentario(data?.registroXUsuarioComentario ?? '');
        })();
    }, [credentials]);

    async function handleRecortar() {
        if (!inCrop) setInCrop(true);
        else {
            setInCrop(false);
            setInRequest(true);
            const cropper = cropperRef.current?.cropper;
            const image = cropper.getCroppedCanvas().toDataURL('image/jpeg');
            const params = {
                usuarioId: userId,
                documentoFotoImg: image.split(',')[1]
            };
            const payload = { ...credentials, params };
            const response = await usuarioFotoExtraidaDNIUpd(payload);

            if (response) {
                if (response.status.code === 1) {
                    setSnackbarInfo({
                        message: "Recorte subido con exito",
                        severity: "success",
                        open: true,
                    });
                    setInRequest(false);
                    closeModal();
                    updateModalList();
                } else {
                    setSnackbarInfo({
                        message: response.status.errmsg,
                        severity: response.status.action === "W" ? "warning" : "error",
                        open: true,
                    });
                    setInRequest(false);
                }
            }
        }
    }

    async function handleConfirmar(event) {
        event.preventDefault();
        const codigo = 'A';
        setInRequest(true);

        const params = {
            usuarioId: userId,
            estadoDeRegistroCod: codigo,
            registroXUsuarioComentario: comentario,
            registroXUsuarioVersion: userInfo.registroXUsuarioVersion,
            usuarioVersion: userInfo.usuarioVersion
        };
        const payload = { ...credentials, params };
        const response = await endUserIdentityUpd(payload);

        if (response) {
            if (response.status.code === 1) {
                if (response.status.id > 0) {
                    const params = {
                        usuarioId: userId,
                    };
                    const payload = { ...credentials, params };
                    NotificatorUsuarioStatusSend(payload);
                }
                setSnackbarInfo({
                    message: "Identidad confirmada",
                    severity: "success",
                    open: true,
                });
                setInRequest(false);
                setTimeout(() => {
                    closeModal();
                    updateModalList();
                }, 3000);
            } else {
                setSnackbarInfo({
                    message: response.status.errmsg,
                    severity: response.status.action === "W" ? "warning" : "error",
                    open: true,
                });
                setInRequest(false);
            }
        }
    }

    async function handleRechazar() {
        const codigo = 'R';
        setInRequest(true);

        const params = {
            usuarioId: userId,
            estadoDeRegistroCod: codigo,
            registroXUsuarioComentario: comentario,
            registroXUsuarioVersion: userInfo.registroXUsuarioVersion,
            usuarioVersion: userInfo.usuarioVersion
        };
        const payload = { ...credentials, params };
        const response = await endUserIdentityUpd(payload);

        if (response) {
            if (response.status.code === 1) {
                if (response.status.id > 0) {
                    const params = {
                        usuarioId: userId,
                        usuarioMail: userInfo.usuarioMail
                    };
                    const payload = { ...credentials, params };
                    NotificatorUsuarioRejectedSend(payload);
                }
                setSnackbarInfo({
                    message: "Usuario eliminado",
                    severity: "success",
                    open: true,
                });
                setInRequest(false);
                setTimeout(() => {
                    closAllModal(true);
                }, 3000);
            } else {
                setSnackbarInfo({
                    message: response.status.errmsg,
                    severity: response.status.action === "W" ? "warning" : "error",
                    open: true,
                });
                setInRequest(false);
            }
        }
    }

    function zoomIn() {
        const currentZoom = fotoDNI.current.style.zoom;

        fotoDNI.current.style.zoom = `${parseInt(currentZoom.split("%")[0]) + 20}%`;
    }
    function zoomOut(e) {
        e.preventDefault();
        const currentZoom = fotoDNI.current.style.zoom;
        fotoDNI.current.style.zoom = `${parseInt(currentZoom.split("%")[0]) - 20}%`;
    }

    const renaperStatusCheck = async (event) => {
        event.preventDefault();
        setInRequest(true);

        const body = {
            service: "RENAPERStatusCheck",
            params: {
                usuarioId: userId,
                usuarioVersion: userInfo.usuarioVersion
            },
        };

        await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo,
            'El proceso terminó exitosamente',
            true
        );

        setTimeout(() => {
            closeModal();
            updateModalList();
        }, 3000);

        setInRequest(false);
    };

    const onCloseConfirmationModal = () => {
        setConfirmationModal((currentValue) => ({ ...currentValue, isOpen: false }));
    };

    return (
        <div className={styles.container}>
            {!userInfo ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <ConfirmationModal
                        onCloseModal={onCloseConfirmationModal}
                        {...confirmationModal}
                    />
                    {!inCrop && <div className={styles.column}>
                        <span className={styles.imageContainer}>
                            <img
                                alt="selfie"
                                className={styles.image}
                                onClick={() => zoomIn()}
                                onDoubleClick={(e) => zoomOut(e)}
                                src={userInfo ? userInfo.imagePathSELFIE : ""}
                            />
                        </span>

                        <span className={styles.imageContainer}>
                            <img
                                alt="documento"
                                className={styles.image}
                                onClick={() => zoomIn()}
                                onDoubleClick={(e) => zoomOut(e)}
                                ref={fotoDNI}
                                src={userInfo ? userInfo.imagePathDNIFTO : ""}
                            />
                        </span>

                        {!!userInfo?.imagePathDNIFTOA && <span className={styles.imageContainer}>
                            <img
                                alt="documento"
                                className={styles.image}
                                onClick={() => zoomIn()}
                                onDoubleClick={(e) => zoomOut(e)}
                                ref={fotoDNI}
                                src={userInfo ? userInfo.imagePathDNIFTOA : ""}
                            />
                        </span>}


                        <span className={styles.imageDNIContainer}>
                            <img
                                alt="frente/dorso DNI"
                                className={styles.image}
                                onClick={() => setFrenteDNI(!frenteDNI)}
                                src={
                                    userInfo
                                        ? frenteDNI
                                            ? userInfo.imagePathDNIFRE
                                            : userInfo.imagePathDNIDOR
                                        : ""
                                }
                            />
                        </span>
                    </div>}
                    {inCrop && <div className={styles.column}>
                            {/* eslint-disable-next-line react/jsx-no-undef */}
                        <Cropper
                            src={userInfo.imagePathDNIFRE}
                            // style={{ height: '3vh', width: "45%" }}
                            // Cropper.js options
                            //initialAspectRatio={16 / 9}
                            checkCrossOrigin={false}
                            guides={false}
                            ref={cropperRef}
                            crossOrigin="anonymous"
                        />
                        </div>}
                    <div className={styles.column}>
                        <div className={styles.text}>
                            <h2>Confirmar identidad</h2>
                            <p>
                                Valida que ambas fotografias coincidan. El
                                objetivo es asegurar que la persona que ves en
                                la foto del documento es la misma de la selfie.
                            </p>

                            <p>
                                Ten en cuenta que las fotografías pueden ser de
                                la misma persona pero de diferente edad o
                                aspecto físico.
                            </p>
                        </div>
                        <form>
                            <div className={styles.inputRow}>
                                <TextField
                                    className={styles.input}
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    label="Match IDX"
                                    margin="normal"
                                    name="idx"
                                    placeholder="0"
                                    sx={{ width: "100%" }}
                                    value={userInfo ? userInfo.usuarioDNIMatchScore?.toFixed(2) : ""}
                                />

                                <TextField
                                    className={styles.input}
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    label="Match Renaper"
                                    margin="normal"
                                    name="renaper"
                                    placeholder="0"
                                    value={userInfo ? userInfo.usuarioDNIMatchRENAPER?.toFixed(2) : ""}
                                />
                            </div>

                            <TextField
                                label="Comentario"
                                name="comentario"
                                placeholder=""
                                margin="normal"
                                value={comentario}
                                onChange={(e) => setComentario(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                multiline={true}
                                fullWidth
                            />

                            <div className={styles.loading}>
                                {inRequest && <CircularIndeterminate />}
                            </div>

                            <div className={styles.buttonRow}>
                                <Button
                                    disabled={inRequest}
                                    onClick={event => { event.preventDefault(); handleRecortar(); }}
                                    texto={inCrop ? "Aceptar Recorte" : "Recortar foto DNI"}
                                />

                                <Button
                                    disabled={inRequest}
                                    onClick={handleConfirmar}
                                    texto={"Confirmar"}
                                />

                                <Button
                                    disabled={inRequest}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setConfirmationModal({
                                            onConfirm: handleRechazar,
                                            title: 'Confirmación de Rechazo',
                                            text: 'Esto eliminará al usuario, deberá realizar nuevamente el onboarding',
                                            isOpen: true,
                                        });
                                    }}
                                    texto={"Rechazar"}
                                />

                                <Button
                                    disabled={inRequest}
                                    onClick={event => renaperStatusCheck(event)}
                                    texto={"Check RENAPER"}
                                />

                                <Button
                                    disabled={inRequest}
                                    onClick={event => { event.preventDefault(); closeModal(); }}
                                    texto={"Cerrar"}
                                />
                            </div>
                        </form>
                    </div>
                </>
            )}
        </div>
    );
}
