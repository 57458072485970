import React, { useEffect, useState } from "react";
import { Fade, Modal, Typography } from "@mui/material";

import close from "assets/icons/borrar.svg";

import { ModalStyles } from "styles/components/UI/Modal.styles";
import Button from "../Button/Button";

export default function ConfirmationModal({
    onConfirm,
    title,
    text,
    isOpen = false,
    onCloseModal = () => { }
}) {
    const styles = ModalStyles();
    const [isOpenModal, setIsOpenModal] = useState(isOpen);

    useEffect(() => {
        setIsOpenModal(isOpen);
    }, [isOpen]);

    useEffect(() => {
        const handleKeyUp = ({ key }) => {
            if (key === "Escape") {
                setIsOpenModal(false);
                onCloseModal();
            }
        };

        if (isOpen) {
            window.addEventListener("keyup", handleKeyUp);
        } else {
            window.removeEventListener("keyup", handleKeyUp);
        }

        return () => {
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [isOpen, onCloseModal]);

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={styles.confirmationModal}
            open={isOpenModal}
        >
            <Fade in={isOpenModal} className={styles.modal}>
                <div className={styles.confirmationModal}>
                    <div className={styles.paper}>
                        <Typography
                            variant="h4"
                            fontWeight={100}
                            className={styles.title}
                            style={{
                                color: title?.includes('Eliminar') ? "#f00" : "",
                            }}
                        >
                            {title}
                        </Typography>
                        <img
                            src={close}
                            className={styles.closeButton}
                            role="button"
                            onClick={() => {
                                setIsOpenModal(false);
                                onCloseModal();
                            }}
                        />
                        {text || '¿Estás seguro que deseás continuar?'}
                        <div className={styles.buttonRow}>
                            <Button
                                onClick={(event) => {
                                    event.preventDefault();
                                    setIsOpenModal(false);
                                    onConfirm();
                                }}
                                texto={"Sí"}
                            />
                            <Button
                                onClick={(event) => {
                                    event.preventDefault();
                                    setIsOpenModal(false);
                                    onCloseModal();
                                }}
                                texto={"No"}
                            />
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}
